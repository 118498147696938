import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import LogoDark from '../assets/images/logo2-dark.png';


const Header = () => (
    <header id="topnav" className="defaultscroll sticky">
        <div className="container">
            <Link className="logo" to="/">
                <img src={LogoDark} height="32" className="logo-light-mode" alt=""/>
                <img src="images/logo-light.png" height="32" className="logo-dark-mode" alt=""/>
            </Link>
            <div className="buy-button">
                <a href="https://console.nitropowered.io" target="_blank" className="btn btn-primary">Login</a>
            </div>
            <div className="buy-button">
                <a href="https://console.nitropowered.io/signup" target="_blank" className="btn btn-primary">Signup</a>
            </div>
            <div className="menu-extras">
                <div className="menu-item">
                    <a className="navbar-toggle" id="isToggle" onClick={() => window.toggleMenu()}>
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>
                </div>
            </div>

            <div id="navigation">
                <ul className="navigation-menu">
                    <li><Link to="/" className="sub-menu-item">Home</Link></li>

                    <li><Link to="/pricing" className="sub-menu-item">Pricing</Link></li>
                    <li><a href="https://nitropowered.freshdesk.com" className="sub-menu-item">Support</a></li>
                </ul>
                <div className="buy-menu-btn d-none">
                    <a href="https://console.nitropowered.io" target="_blank" className="btn btn-primary">Login</a>
                </div>
            </div>
        </div>
    </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
