import { Link } from "gatsby";
import * as React from "react"

import LogoLightImage from '../assets/images/logo2-light.png';

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <img src={LogoLightImage} height="24" alt=""/>
                        </a>
                        <p class="mt-4">Start using Nitro and focus on your business, and not running your applications.</p>
                        <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                            <li class="list-inline-item"><a href="https://twitter.com/NitroPowerHost" class="rounded"><i
                                        data-feather="twitter" class="fea icon-sm fea-social"></i></a></li>
                            <li class="list-inline-item"><a href="mailto:support@nitropowered.io" class="rounded"><i
                                        data-feather="mail" class="fea icon-sm fea-social"></i></a></li>
                        </ul>
                    </div>

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Company</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><Link to="/pricing" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Pricing</Link></li>
                            <li><Link to="https://nitropowered.freshdesk.com/" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Support</Link></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">About</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><Link to="/tos" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>Terms of Service</Link></li>
                            <li><a href="/privacy-policy" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <footer class="footer footer-bar">
            <div class="container text-center">
                <div class="row align-items-center">
                    <div class="col-sm-6">
                        <div class="text-sm-start">
                            <p class="mb-0">©
                                {year} Nitro Powered.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <a href="#" onClick={() => window.topFunction()} id="back-to-top" class="btn btn-icon btn-primary back-to-top"><i
                data-feather="arrow-up" class="icons"></i></a>
        </>
    )
};

export default Footer;